<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Modifica zona geografica {{ zone ? zone.name : '' }}</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'suppliers.zones' }">Lista zone</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <ZoneForm v-if="zone" :value="zone" :feedback.sync="feedback" @submitForm="saveZone" edit />
    </div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import ZoneForm from '@/views/components/Form/ZoneForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    ZoneForm,
  },
  data () {
    return {
      zone: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'suppliers.zones' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveZone (zoneForm) {
      const zone = { ...zoneForm };

      this.$api.updateZone(zone.id, zone)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    $api.fetchZone(to.params.id)
      .then(res => {
        return next(vm => {
          vm.zone = res?.data || {};
        });
      })
      .catch(err => {
        return next(vm => vm.$log.error(err));
      })
    ;
  },
};

</script>
